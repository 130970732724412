/****************** Font ***********************/
@font-face {
  font-family: "DefaultFont";
  src: local("DefaultFont"),
    url(./Fonts/Quattrocento-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "DefaultFont-Bold";
  font-weight: 500;
  src: local("DefaultFont-Bold"),
    url(./Fonts/Quattrocento-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "DefaultFont-fun";
  font-weight: 500;
  src: local("DefaultFont-fun"),
    url(./Fonts/ShadowsIntoLight-Regular.ttf) format("opentype");
}

/****************** Main ***********************/

:root {
  --color-white: #fff;
  --color-main: rgba(193, 226, 231, 1);
  --color-mainDark: rgb(57, 145, 156);
  --color-dark: rgba(255, 255, 255, 0);
}

body {
  font-family: "DefaultFont" !important;
  min-height: 100vh;
  background-color: var(--color-main) !important;
}

.body-wrapper {
  min-height: 95vh;
  margin-top: 5rem;
  margin-bottom: 1rem;
  /* background-color: gray !important; */
}

/* .container {
  font-family: "DefaultFont" !important;
} */

.loading {
  text-align: center;
  background-color: var(--color-main) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.logo {
  width: 8.5rem;
}

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

nav a.visit {
  letter-spacing: 0.0375rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  padding-right: 0px !important;
  margin-right: 0px !important;
  float: right;
}

nav a.active {
  color: var(--color-main);
}

/* nav ul li {
  text-transform: uppercase;
} */

/* Table */
tr {
  line-height: 3px;
  /* min-height: 25px;
  height: 28px; */
}

/* Table End */

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--color-white) !important;
  border-top: 0.1875rem solid var(--color-dark);
}

/* .navbar-nav > li > a.active {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--color-white) !important;
  border-top: 0.1875rem solid var(--color-main);
} */

nav li > a.active:focus,
nav li > a:hover {
  border-top: 0.1875rem solid var(--color-main);
}

nav li > a.visit:hover {
  border-top: 0.1875rem solid var(--color-dark);
}

a.nav-link.active {
  border-top: 0.1875rem solid var(--color-main);
  color: var(--color-main) !important;
  font-weight: bolder;
}

.navbar-brand {
  max-width: 20.25rem;
  padding: 0px !important;
  height: 17rem !important;
}

/****************** Header ***********************/
.main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.main-info h1 {
  color: var(--color-white) !important;
  /* text-transform: uppercase; */
}

.fun {
  font-family: "DefaultFont-fun" !important;
  color: var(--color-white);
  margin: 0px;
  font-size: 1.75rem;
  padding: 0.313rem;
}

/* ____________Card view_________________ */
/* EFFECT 3 ========================================== */
.effect-3 {
  position: relative;
}

.card {
  margin: 0rem !important;
  padding: 1.626rem !important;
  background-color: var(--color-white) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* flex-direction: row !important; */
}

.normalcard {
  margin: 0rem !important;
  padding: 1.626rem !important;
  background-color: var(--color-white) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* flex-direction: row !important; */
}

/* .effect-3::before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #aaa;
  box-shadow: 0 15px 10px #aaa;
  transform: rotate(-3deg);
} */

.nopaddingcard {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.fullbodycard {
  margin: 0rem !important;
  padding: 0.626rem !important;
  background-color: var(--color-white) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

/* .clearcard {
  margin: 0.626rem !important;
  padding: 0.626rem !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
} */

/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

/* a {
  text-decoration: none !important;
  color: inherit !important;
} */
.onlymobile {
  display: none !important;
}

.closeBtn {
  height: 30px;
  width: 30px;
  position: absolute;
  /* background: red;
  color: white; */
  top: -10px;
  right: -10px;
  border-radius: 50%;
}

.addTop {
  /* height: 120px !important; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
  padding: 20px;
}

.outerLink {
  text-decoration: none !important;
  color: inherit !important;
}

.formElement {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  margin-left: 0px !important;
}

.formHeader {
  width: 100%;
  /* height: 30px; */
  margin-top: 8px !important;
  margin-left: 0px !important;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin: 10px !important;
}

.thumbnail {
  width: 30px !important;
  height: 30px !important;
  margin: 10px !important;
}

.header {
  font-size: 20px;
  font-weight: bold;
}

.tablerow {
  margin: 10px !important;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
}

.modal-90w {
  max-width: 50% !important;
}

.canvas {
  width: 95% !important;
  margin-right: 10% !important;
  text-align: center;
}

.btnPopup {
  position: absolute;
  bottom: 0;
  width: 94% !important;
  margin-left: 5px;
  margin-right: 5px;
  display: block;
  background-color: var(--color-mainDark) !important;
}

.normalBtn {
  background-color: var(--color-mainDark) !important;
  margin-bottom: 10px;
}

.highlightedText {
  color: var(--color-mainDark);
  font-size: 60px !important;
  font-size: 3.5vw;
}

.greenText {
  color: var(--color-mainDark) !important;
  width: 100% !important;
  padding-top: 8px;
}
.imgPopup {
  width: 100%;
  height: auto;
}

.padding10 {
  padding-left: 10px;
}

/* row */
.row-bordered {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  margin: 0 15px;
}

/* _________________ For tablets _________________  */

@media (max-width: 1023px) {
  .highlightedText {
    color: var(--color-mainDark);
    font-size: 30px !important;
    font-size: 3.5vw;
  }
}

/* _________________ For Mobile _________________  */

@media (max-width: 768px) {
  .main-info h1 {
    color: var(--color-white) !important;
    /* text-transform: uppercase; */
    font-size: 1.5rem;
  }

  .header-wrapper h1 {
    color: #fff;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    margin-top: 50px;
  }

  .card {
    margin: 1rem !important;
    padding: 1.626rem !important;
    background-color: var(--color-white) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .noramlcard {
    margin: 0rem !important;
    padding: 1.626rem !important;
    background-color: var(--color-white) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .fullbodycard {
    margin: 0rem !important;
    padding: 0.626rem !important;
    background-color: var(--color-white) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .body-wrapper {
    min-height: 95vh;
    margin-top: 5rem;
    margin-bottom: 15px;
    /* margin-top: 70px !important;
    margin-bottom: 0px !important; */
  }

  .modal-90w {
    max-width: 100% !important;
  }

  .imgPopup {
    width: 100%;
    height: 50px;
    display: none !important;
  }

  .highlightedText {
    color: var(--color-mainDark);
    font-size: 40px !important;
    font-size: 3.5vw;
    width: 100% !important;
  }

  .popupContainer {
    margin: 25px;
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .canvas {
    width: 100% !important;
    margin-right: 10% !important;
    text-align: center;
  }

  .normal {
    text-overflow: ellipsis;
  }

  .btnPopup {
    position: static !important;
    width: 98% !important;
    background-color: var(--color-mainDark) !important;
  }

  nav a.visit {
    letter-spacing: 0.0375rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    padding-right: 5px !important;
    float: right;
  }

  .onlymobile {
    display: inline !important;
    width: 90vw;
  }
}

.logo {
  width: 90px;
}

.MPCLogo {
  width: 97%;
}

.centered-text {
  text-align: center;
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  /* Set the height of the container */
}

.MPCLogo {
  width: 300px;
  /* Set the width of the image */
  height: 300px;
  /* Set the height of the image */
  /* Add any additional styling for the image if needed */
}