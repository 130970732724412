video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.carousel {
  border-style: double !important;
  border-radius: 5px;
}

.imgHeader {
  /* background-image: url("./../Images/Cover.jpeg"); */
  border: 1px solid #ccc;
  display: inline-block;
  padding: 20px 50px;
  width: 55%;
  height: 320px;
  cursor: pointer;
  background: url("./../Images/3.png"); /* this changes */
  margin: 0 0 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.hero-container {
  background: url("/src/Images/3.png") center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 80px;
  margin-top: -100px;
  padding: 10px;
  font-family: "DefaultFont";
}

.hero-container > h2 {
  padding: 15px;
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "DefaultFont";
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 24px;
  font-family: "DefaultFont";
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 34px;
    margin-top: -100px;
  }

  .hero-container > h2 {
    margin-top: 5px;
    color: #fff;
    font-size: 24px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
  }

  .hero-container > p {
    font-size: 16px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
