.aboutme-wrapper {
  /* padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px; */
  /* border-radius: 5px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.4);
    border-style: solid; */
}

.profilecard {
  margin: 0rem !important;
  margin-right: 0.626rem !important;
  padding: 0px !important;
  background-color: var(--color-white) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0.25rem;
  position: sticky;
  top: 80px;
  /* padding-bottom: 0.25rem !important; */
  /* flex-direction: row !important; */
}

.profileDetail {
  margin-top: 10px;
  margin-bottom: 20px;
}

.profileDetail p {
  text-align: center;
  /* margin-top: -15px; */
}

.profilepic {
  max-width: 80%;
  margin: 10%;
  border-color: gray;
  align-self: center;
  border-radius: 0.25rem;
  border-style: double;
  border-radius: 50%;
}

.imageContainer {
  max-width: 100%;
  /* margin: 10%; */
  border-color: gray;
  align-self: center;
  border-radius: 0.25rem;
  /* border-style: double; */
}

.newcard {
  background-color: white;
}

.socialContainer {
  margin: 0.313rem;
  padding-top: 8px;
}

.socialContainer * {
  margin-right: 5px;
  margin-left: 5px;
}

/* .outerLink {
  width: 40px !important;
  height: 40px !important;
  margin-bottom: 5px;
} */

.socialIcon {
  height: 30px;
}

.icon {
  margin-left: 5px;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .profilecard {
    margin: 1rem !important;
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    padding: 0rem !important;
    background-color: var(--color-white) !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 0.25rem;
    padding-bottom: 0.25rem !important;
    /* flex-direction: row !important; */
  }

  .body-wrapper {
    min-height: 95vh;
    margin-top: 5rem;
    margin-bottom: 10px !important;
  }
}